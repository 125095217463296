import AsyncStorage from '@react-native-async-storage/async-storage';
// https://github.com/react-native-community/async-storage

let _events = {};

// ----------------------------------------------------------------------

function init() {
	global.settings = {};
	
	return AsyncStorage.getAllKeys().then(async (keys) => {
		await AsyncStorage.multiGet(keys, (err, stores) => {
			stores.map((result, i, store) => {
				// get at each store's key/value so you can work with it
				let key = store[i][0];
				if (key.substring(0, 4) === 'pai-') {
					key = key.substring(4);
					let value = store[i][1];
					global.settings[key] = value;
				}
				return true;
			});
		});
	});
}

function local_getCheckbox(hash, default_value) {
	if (global.settings.hasOwnProperty(hash)) {
		let value = global.settings[hash];
		value = value !== 'false' && value !== '0' ? 1 : 0;
		return value;
	}
	return default_value;
}

function local_setCheckbox(hash, newvalue) {
	newvalue = newvalue ? '1' : '0';
	global.settings[hash] = newvalue;
	let key = 'pai-' + hash;
	AsyncStorage.setItem(key, newvalue);		// async. may take a while to set
}

// any hash entry is a checkbox
function local_removeCheckboxes() {
	let re = new RegExp('[0-9a-f]{16}');
	for(let key in global.settings) {
		if (re.test(key)) {
			local_removeValue(key);
		}
	}
}

function local_getValue(hash, default_value) {
	if (global.settings.hasOwnProperty(hash)) {
		return global.settings[hash];
	}
	return default_value;
}

function local_setValue(key, newvalue) {		// must be a string
	global.settings[key] = newvalue;
	key = 'pai-' + key;
	AsyncStorage.setItem(key, newvalue);
	return newvalue;
}

function local_removeValue(key) {
	delete global.settings[key];
	key = 'pai-' + key;
	AsyncStorage.removeItem(key);
}

function getAPIurl(previewmode) {
	if (previewmode) return source(1);
	return source(local_getValue('location', 0));
}


// ----------------------------------------------------------------------
function on(name, callback) {
	if (typeof _events[name] === 'undefined') {
		_events[name] = [];
	}
	_events[name].push(callback);
}
function off(name, callback) {
	if (typeof _events[name] !== 'undefined') {
		for(let i = 0; i < _events[name].length; i++) {
			if (_events[name][i] === callback) {
				_events[name].splice(i, 1);
				break;
			}
		}
	}
}
function trigger(name, param) {
	if (typeof _events[name] !== 'undefined') {
		for(let i = 0; i < _events[name].length; i++) {
			_events[name][i](param);
		}
	}
}
// ----------------------------------------------------------------------

	function source(id) {
		if (typeof id == 'string') id = parseInt(id);
		switch(id) {
			case 0:
				return 'https://patientapps.net/api';
			case 1:
				return 'https://beta.patientapps.net/api';
			default:
				return 'https://' + id + '.unknown.patientapps.net/api';
		}
	}

export default {
  init,
  local_getCheckbox,
  local_setCheckbox,
  local_removeCheckboxes,
  local_getValue,
  local_setValue,  
  local_removeValue,
  on,
  off,
  trigger,
  getAPIurl,
  source,
};