import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  StyleSheet,
  Platform,
} from 'react-native';
import * as Linking from 'expo-linking';
import ProcedureData from '../components/ProcedureData';
import { withNavigation, NavigationActions } from 'react-navigation';

// https://stackoverflow.com/questions/30540252/display-hyperlink-in-react-native-app

class HyperLink extends Component {

  constructor(){
      super();
      this._goToURL = this._goToURL.bind(this);
  }

  static propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }

  _generateHTMLlink(url, title) {
	  return (
		<a style={{fontWeight: 'bold', color: 'blue', textDecoration: 'none'}} href={url} target={"_blank"}>{title}</a>
	  );
  }

  render() {

    const { title, url } = this.props;
	var stylex = this.props.style;
	
	// create a real html link. Allows page to be displayed in new tab
	if (Platform.OS == 'web' && url.indexOf('://') > -1) {
		return this._generateHTMLlink(url, title);
	}

	// TouchableOpacity is Animated.View, so cannot use it
    return(
		<Text style={[stylex, {fontWeight: 'bold', color: 'blue'}]} onPress={this._goToURL}>{title}</Text>
    );
  }

  _goToURL() {
    const { url } = this.props;
	if (url.substring(0, 1) === '*') {
		let pagehash = url.substring(1);
		let pg = ProcedureData.getPageFromHash(pagehash);
		this.props.navigation.navigate('content', {
              page: pg + 1
		});
		return;
	}
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(this.props.url);
      } else {
        console.log('Don\'t know how to open URI: ' + this.props.url);
      }
    });
  }
}

export default withNavigation(HyperLink);