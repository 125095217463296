// PaiTable - a HTML-type table
import React, { Component } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Table, TableWrapper, Row, Rows, Col } from './Tables';
import FakeHtmls from '../components/FakeHtml';

// used by pai-ldn, pai-fass

export default class PaiTable extends Component {
  constructor(props) {
    super(props);
    this.state = { 
		tableHead: [],
		tableData: [],
	};
	this.columnwidths = [];
	let value;
	if (props.page.header) {
		for(let i = 0; i < props.page.header.length; i++) {
			this.columnwidths.push(1);
			value = this.formattedText(props.page.header[i], true);
			this.state.tableHead.push(value);
		}
	}
	if (props.page.rows) {
		for(let row = 0; row < props.page.rows.length; row++) {
			let rowdata = props.page.rows[row];
			let temp = [];
			for(let col = 0; col < rowdata.length; col++) {
				value = this.formattedText(rowdata[col], false);
				temp.push(value);
			}
			this.state.tableData.push(temp);
		}
	}
  }

	formattedText(text, isHeader = false) {
		let value = FakeHtmls.assembleStyledText(text, 20, '#222');
		return (
			<Text selectable={true} style={{fontSize: 20, marginLeft: 5, paddingTop: 2, paddingBottom: 2}}>{value}</Text>
		);
	}

  render() {
    const state = this.state;
    // textStyle={} is unused, so remove.  Styles in formattedText() apply for both header and data
    if (state.tableHead.length) {
        return (
          <View style={styles.container}>
            <Table borderStyle={{borderWidth: 1, borderColor: '#59a0cf'}}>
              <Row data={state.tableHead} flexArr={this.columnwidths} style={styles.head} />
              <TableWrapper style={{flexDirection: 'column'}}>
                <Rows data={state.tableData} flexArr={this.columnwidths} style={styles.row} />
              </TableWrapper>
            </Table>
          </View>
        )
    } else {        // table does not have a header row:
        return (
          <View style={styles.container}>
            <Table borderStyle={{borderWidth: 1, borderColor: '#59a0cf'}}>
              <TableWrapper style={{flexDirection: 'column'}}>
                <Rows data={state.tableData} flexArr={this.columnwidths} style={styles.row} />
              </TableWrapper>
            </Table>
          </View>
        )
    }
  }
}

const styles = StyleSheet.create({
  container: { flex: 1, paddingTop: 16, paddingBottom: 16 },	// , backgroundColor: '#ffffff' 
  head: {  backgroundColor: '#f1f8ff'},
  title: { flex: 1, backgroundColor: '#f6f8fa' },
  row: {   backgroundColor: '#ffffff'  },                       // height should not be set.
});