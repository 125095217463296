import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  Dimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import * as Linking from 'expo-linking';
import * as Font from 'expo-font';

import background_next from '../theme/homepage-next.png';
import ProcedureData from '../components/ProcedureData';
import PaiCalendar from '../components/PaiCalendar';
import Frame from '../components/Frame';
import Constants from 'expo-constants';

import { createIconSetFromIcoMoon } from '@expo/vector-icons';
const Icon = createIconSetFromIcoMoon(
    require('../theme/icomoon.json'), 
    'icomoon', 
    '../theme/icomoon.ttf'
);

const PageStyle = StyleSheet.create({

    inner_content: {
        flexDirection: 'column',
        alignSelf: 'center',
        width: '90%',
    },
    title_section: {
//		borderColor: '#900',
//		borderWidth: 1,
        width: '100%',
        alignItems: 'center',
    },
    title_next: {
        marginTop: 16,
        marginBottom: 16,
        width: 170,
        height: 55,
        backgroundImage: `url(${background_next})`,
        backgroundSize: 'cover',
    },
    sec_header: {
        marginTop: 8,
        marginBottom: 8,
        color: '#ffff00',
        fontFamily: 'exo2bold',
        textAlign: 'center',
    },
    sec_title: {
        marginTop: 8,
        marginBottom: 8,
        color: '#ffffff',
        fontFamily: 'exo2bold',
        textAlign: 'center',
    },
    sec_tagline: {
        marginTop: 8,
        marginBottom: 8,
        color: '#ffff00',
        fontFamily: 'exo2lite',
        fontStyle: 'italic',
        textAlign: 'center',
    },
    action: {
        fontSize: 26,
        color: '#414042',
        position: 'absolute',
        fontFamily: 'exo2lite',
        right: 8,
        top: 9,
    },
    sec_language: {
    },
    link_text: {
        fontWeight: 'bold',
        fontFamily: 'exo2lite',
        color: '#000099',
    },
    footer_container: {
        marginTop: 27, 
        flex: 6, 
        flexDirection: 'row', 
        width: 360, 
        alignItems: 'center', 
        paddingTop: 5, 
        paddingBottom: 5, 
        backgroundColor: 'rgba(255,255,255,0.2)', 
        borderRadius: 5,
    }
});

export default class TitlePage extends Component {

	constructor (props) {
		super(props);
        this.state = {
            fontsLoaded: false,
            sizeChanged: 0,
        };
        this.sec_header = StyleSheet.flatten(PageStyle.sec_header);
        this.sec_title = StyleSheet.flatten(PageStyle.sec_title);
        this.sec_tagline = StyleSheet.flatten(PageStyle.sec_tagline);
        this.getFactors();
    }

    async LoadFonts() {
        await Font.loadAsync({
            icomoon: require('../theme/icomoon.ttf'),
            exo2bold: require('../theme/Exo2-SemiBold.otf'),
            exo2lite: require('../theme/Exo2-Light.otf'),
        });
        this.setState({ 
            fontsLoaded: true,
            sizeChanged: this.state.sizeChanged + 1,
        });
    }

	componentDidMount() { 
        this.LoadFonts();
 		PaiCalendar.load();
	}

    onpress = () => {
        if (this.props.expired) {
            let content_text = ProcedureData.contentText();
            if (content_text && content_text.infolink) {
                Linking.openURL(content_text.infolink);
            }
        } else {
            this.props.dismiss();
        }
    }

    homepage = () => {
        Linking.openURL('https://patientapps.com');
    }
    website = () => {
		let content_text = ProcedureData.contentText();
        if (content_text && content_text.infolink) {
            Linking.openURL(content_text.infolink);
        } else {
            this.onpress();
//            this.props.dismiss();
        }
    }
    
    prepareFooter(info) {
        let txt = null;
        if (info.gratis && !this.props.expired) {
            txt = (
                <Text onPress={this.website}>Gratis Period: <Text style={PageStyle.link_text}>{info.gratis}</Text>{`\n`}</Text>
            );
        }
        return (
            <View style={PageStyle.footer_container}>
                <View style={{flex: 2}}><Icon name="pai-icon" size={80} color="#ffff00" style={{alignSelf: 'center'}}/></View>
                <View style={{flex: 5}}>
                    <Text style={{color: '#414042',fontFamily: 'exo2bold', fontSize: 27}}>PATIENT<Text style={{color: '#ffff00'}}>APPS</Text>{`\n`}</Text>
                    <Text style={{color: '#414042',fontFamily: 'exo2lite', fontSize: 16}}>{txt}{info.text_version}{`\n`}{info.copyright}, <Text onPress={this.homepage} style={PageStyle.link_text}>PatientApps, Inc.</Text>{`\n`}</Text>
                </View>
            </View>
        );
    }
    
    continueButton(info) {
        return (
            <ImageBackground 
                source={require('../theme/homepage-next.png')}
                imageStyle={{resizeMode: 'cover'}}
                style={{width:170, height: 55}}>
                <Text style={PageStyle.action}>{info.text_continue}</Text>
            </ImageBackground>
        );
    }
    
    expiredText(info) {
        return (
            <Text style={[this.sec_title, {fontSize: 24} ]}>Trial has Expired</Text>
        );
    }
    
    getFactors() {
        let factor;
		var { width, height } = Dimensions.get('window');
        if (width < 512) {
            factor = 1;
        } else if (width < 2048) {
            // font-size: calc(1 + (0.5) * ((100vw - 512px) / (2048 - 512))); 
            factor = 1 + 0.5 * ((width - 512) / 1536);
        } else {
            factor = 1.5;
        }
        this.fs_s = 18.6 * factor;
        this.fs_m = 21.3 * factor;
        this.fs_l = 26.6 * factor;
    }

	onLayout = e => {
        this.getFactors();
        this.setState({ sizeChanged: this.state.sizeChanged + 1 });
	};

	render() {
        if (!this.state.fontsLoaded) {
            return null;
		}

//		let {height, width} = Dimensions.get('window');
//		let land = width > height;
        let info = ProcedureData.productDetails();
        
        let footer = this.prepareFooter(info);
        let action = this.props.expired ? this.expiredText(info) : this.continueButton(info);
        
		return (
			<Frame>
				<TouchableOpacity onPress={this.onpress}><View style={PageStyle.inner_content} onLayout={this.onLayout}>
                    <View style={PageStyle.title_section}><Text style={{ ...this.sec_header, fontSize: this.fs_m}}>{info.text_header}</Text></View>
                    <View style={PageStyle.title_section}><Text style={{ ...this.sec_title, fontSize: this.fs_l}}>{info.title}</Text></View>
                    <View style={PageStyle.title_section}><Text style={{ ...this.sec_tagline, fontSize: this.fs_s}}>{info.tagline}</Text></View>
                    <View style={PageStyle.title_section}>{action}</View>
                    <View style={PageStyle.title_section}><Text style={PageStyle.sec_language}></Text></View>
                    <View style={PageStyle.title_section}>{footer}</View>
				</View></TouchableOpacity>
			</Frame>
		);
        
	}
}

