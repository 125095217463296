import React, { Component } from 'react';
import { Text, View } from 'react-native';
import FakeHtmls from '../components/FakeHtml';

// Handle the two special lineitems:  "Ready!" and "Not Ready!"
import Storage from '../components/Storage';

function load(page) {
	var result = {};
	if (page.type == 'lineitem') {
		let special = typeof page.special !== 'undefined' ? page.special : 0;
		if (special) {
			result[special] = page.hash;
		}
	}
	if (typeof page.children != 'undefined') {
		for(let i = 0; i < page.children.length; i++) {
			var info = load(page.children[i]);
			for(var key in info) {
				result[key] = info[key];
			}
		}
	}
	return result;
}

function countUncheckedOptions(page) {
	var result = 0;
//	if (page.type === 'tab') {
//		var tabok = activeTab == '' || page.hash == activeTab;
//		if (!tabok) return 0;
//	} else 
	if (page.type === 'lineitem') {
		let special = typeof page.special !== 'undefined' ? page.special : 0;
		if (special) return 0;
		let checked = Storage.local_getCheckbox(page.hash, false);
		return checked ? 0 : 1;
	}
	if (typeof page.children != 'undefined') {
		for(let i = 0; i < page.children.length; i++) {
			let info = countUncheckedOptions(page.children[i]);	// , activeTab
			if (info) result += info;
		}
	}
	return result;
}

// are all options for this tab checked?
function addSpecialText(page, uncheckedCount) {
	let checked = Storage.local_getCheckbox(page.hash, false);
	var customblock = {};
	if (checked) {
		var newArray = page.children.map(a => Object.assign({}, a));
		if (page.special == 200) {		// Ready
			if (uncheckedCount > 0) {
				customblock = {
					type: 'special',
					caption: 'Action Required',
					text: 'Be sure to complete your checklist so that the hospital team knows you are ready!',
					color: '#900',
				};
				
			} else {
				customblock = {
					type: 'special',
					caption: 'Congrats, you are good to go!',
					text: 'Please contact your team at the hospital to confirm you are ready for your procedure!',
					color: '#090',
				};
//             subTitle: 'A message will be sent to the team at the hospital to confirm you are ready for your procedures',
			}
		} else {						// Not Ready
				customblock = {
					type: 'special',
					caption: 'Are you sure you need to cancel?',
					text: 'Please contact your surgeon’s office <icon name="telephone" /> <a href="{{phone_office}}">PHYSICIAN’S OFFICE</a> to cancel or reschedule',
					color: '#900',
				};
//              title:'Are you sure you need to cancel?',
//              subTitle: 'A member of the hospital team will contact you shortly to confirm your decision and reschedule the procedure.',

//              title:'Are you sure you need to cancel?',
//              subTitle: 'Please contact your surgeon’s office <img src="assets/imgs/telephone.svg" class="inline-icon"> <a href="tel:857-971-5065">PHYSICIAN’S OFFICE</a> to cancel or reschedule',

		}
		newArray.splice(0,0, customblock);
		return newArray;
	} else {
		return page.children;
	}
}

function generateCustomBlock(page, key) {
	if (page.type !== 'special') return null;
	let col = page.color;
	return (
		<View key={key} style={{borderColor: col, borderRadius: 5, borderWidth: 1, padding: 2}}>
			<View style={{borderColor: col, borderRadius: 5, borderWidth: 1, padding: 2}}>
				<View>
					<Text style={{color: col, fontSize: 20, fontWeight: 'bold', padding: 10}}>{page.caption}</Text>
				</View>
				<View>
					<Text style={{color: col, fontSize: 18, padding: 10}}>{FakeHtmls.assembleStyledText(page.text, 18)}</Text>
				</View>
				
			</View>
		</View>
	)
}

export default {
	load,
	countUncheckedOptions,
	addSpecialText,
	generateCustomBlock,
}