// Keep track of which dropdowns belong to which list,
// and which ones are open
// there is one instance of an accordion for each set of dropdowns

import Panel from '../components/Panel';
import { Platform } from 'react-native';

class accordion {
	
	children = [];
	firstId = false;
	activeDropdown = -1;
	lastDropdown = null;
	
	constructor(group) {
		this.changeDropDown = this.changeDropDown.bind(this);
		this.animationEnd = this.animationEnd.bind(this);
		this.clear();
		this.group = group;
	}

	// clear
	clear() {
		this.children = [];
		this.firstId = false;
		this.lastDropdown = null;		// what is the last dropdown opened for this group
		this._to_be_expanded = null;
		this.activeDropdown = -1;
	}

	initialDropdown() {
		if (arguments.length) {
			this.activeDropdown = arguments[0];
			return this;
		}
		return this.activeDropdown;
	}

	_indexById(id) {
		let existing = -1;
		for(let i = 0; i < this.children.length; i++) {
			if (this.children[i] !== null && this.children[i].props.id == id) {
				existing = i;
				break;
			}
		}
		return existing;
	}

	// add lineitem
	addItem(panel) {
		let existing = this._indexById(panel.props.id);
		if (existing >= 0) {
			this.children[existing] = panel;
		} else {
			panel._parent = this;
			this.children.push(panel);
		}
		panel.redraw();					// redraw control: expanded/collapsed
	}

	removeItem(id) {
//		console.log('removing item', this.group, id);
		let existing = this._indexById(id);
		if (existing >= 0) this.children[existing] = null;
	}

	// set accordion item
	getItemById(id) {
		let existing = this._indexById(id);
		return existing >= 0 ? this.children[existing] : null;
	}

	getItemByIndex(index) {
		for(let i = 0; i < this.children.length; i++) {
			let node = this.children[i];
			if (node !== null && typeof node.props.index !== 'undefined' && node.props.index == index) {
				return node;
			}
		}
		return null;
	}

	unload() {
		let id = this.activeDropdown;
		let current_item = this.getItemById(id);
		if (current_item !== null) {
			current_item.resetItem();
		}
		this.lastDropdown = null;
		this._to_be_expanded = null;
	}

	// open current item
/*	initialLoad() {
		this._to_be_expanded = null;
		if (this.lastDropdown !== null) {	// close all
			this.lastDropdown.setState({hilite: false, animated: false, expanded: false});
		}
		if (this.activeDropdown > -1) {
			// close any previous one
			let current_item = this.getItemById(this.activeDropdown);
			this.lastDropdown = current_item;
			current_item.setState({hilite: true, animated: false, expanded: true});
		}
	} */

	// expand/collapse, with hilite
	changeDropDown(id, expanded) {
		let result = false;
		let current_item = this.getItemById(id);
		var oldstate = current_item.state.expanded;

		// a dropdown is active - close it.
		if (this.lastDropdown !== null) {
			this.lastDropdown.collapse();

			// this will run the expand animation once the collapse animation is complete
			if (this.lastDropdown.props.id != id) {
				this._to_be_expanded = current_item;
				current_item.setState({hilite: true, animated: true});
			} else {			// clicked on same expanded item
				this._to_be_expanded = null;
				this.activeDropdown = -1;
			}
			this.lastDropdown = null;
		} else if (!oldstate && expanded) {		// collapsed -> expanded
			let depth = typeof current_item.props.page.depth === 'undefined' ? 1 : current_item.props.page.depth;
			if (depth == 1) result = current_item.position.top;				// scroll, at start of opening dropdown anitmation
			current_item.setState({hilite: true, animated: true});
			this.lastDropdown = current_item;
			this.activeDropdown = current_item.props.id;
		}
		return result;		// position to scroll to, if not FALSE.
	}

	// end collapse animation will start queued open animation, updating hilite
	animationEnd(id, expanded) {
		let result = false;
		if (this._to_be_expanded !== null) {
			let new_item = this._to_be_expanded;
			this.activeDropdown = new_item.props.id;
			this._to_be_expanded = null;

			let depth = typeof new_item.props.page.depth === 'undefined' ? 1 : new_item.props.page.depth;
			if (depth == 1) result = new_item.position.top;
			new_item.setState({hilite: true, animated: true});
			this.lastDropdown = new_item;
		}
		return result;
	}

}


export default accordion;