import { createBrowserApp } from '@react-navigation/web';
import { createSwitchNavigator, createStackNavigator } from 'react-navigation';
import {createDrawerNavigator} from 'react-navigation-drawer';

import MainTabNavigator, {ContentStack} from './MainTabNavigator';
import DrawerScreen from '../components/DrawerScreen';
import NavigationService from '../components/NavigationService';

const DrawerNavigator = createDrawerNavigator({
    Main: MainTabNavigator,
},{
    contentComponent: DrawerScreen,
    drawerWidth: 250,
    drawerPosition: 'left',
	initialRouteName: 'Main',
    overlayColor: 'rgba(0,0,0,0.7)',
})
DrawerNavigator.path = '';

const switchNavigator = createSwitchNavigator({
  // You could add another route here for authentication.
  // Read more at https://reactnavigation.org/docs/en/auth-flow.html
  DrawerNavigator: DrawerNavigator,
});
switchNavigator.path = '';

export default createBrowserApp(switchNavigator, { history: 'hash'});

