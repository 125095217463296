// Web only
// https://sghall.github.io/react-compound-slider/#/getting-started/tutorial

import React, { Component } from 'react';
import Styles from '../theme/Styles';
import { Text, View } from 'react-native';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'

function Track({ source, target, getTrackProps, height }) { // your own track component
  return (
    <div
      style={{
        position: 'absolute',
        height: 10,
        zIndex: 1,
        marginTop: height / 2 - 5,
        backgroundColor: '#546C91',
        borderRadius: 5,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()} // this will set up events if you want it to be clickable (optional)
    />
  )
}

export function Handle({ // your handle component
  handle: { id, value, percent }, 
  getHandleProps,
  height
}) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        marginLeft: -15,
        marginTop: height / 2 - 15,
        zIndex: 2,
        width: 30,
        height: 30,
        border: 0,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: '#2C4870',
        color: '#333',
      }}
      {...getHandleProps(id)}
    >
    </div>
  )
}

export default class Slides extends Component {

	constructor(props){
		super(props);
		this.state = {
			position: 0
		}
	}

	render() {
		const { ...rest } = this.props;

		let wd = this.props.style.width;
		let ht = this.props.style.height;
		
		let sliderStyle = {  // Give the slider some width
		  position: 'relative',
		  width: wd,
		  height: ht,
//		  border: '1px solid steelblue',
		}

		let railStyle = { 
		  position: 'absolute',
		  width: wd,
		  height: 10,
		  marginTop: ht / 2 - 5,
		  borderRadius: 5,
		  backgroundColor: '#ccc',	// '#8B9CB6'
		}
		
		var value = [this.props.value];
		return (
			<View>
				  <Slider
					rootStyle={sliderStyle}
					domain={[0, 1]}
					step={0.01}
					mode={3}
					values={value}
					onSlideEnd={this.props.onSlidingComplete}
				  >
					<Rail>
					  {({ getRailProps }) => (  // adding the rail props sets up events on the rail
						<div style={railStyle} {...getRailProps()} /> 
					  )}
					</Rail>
					<Handles>
					  {({ handles, getHandleProps }) => (
						<div className="slider-handles">
						  {handles.map(handle => (
							<Handle
							  key={handle.id}
							  handle={handle}
							  getHandleProps={getHandleProps}
							  height={ht}
							/>
						  ))}
						</div>
					  )}
					</Handles>
					<Tracks right={false}>
					  {({ tracks, getTrackProps }) => (
						<div className="slider-tracks">
						  {tracks.map(({ id, source, target }) => (
							<Track
							  key={id}
							  source={source}
							  target={target}
							  getTrackProps={getTrackProps}
							  height={ht}
							/>
						  ))}
						</div>
					  )}
					</Tracks>
				  </Slider>
			</View>
		)
	}
	
}

