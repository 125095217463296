import React from 'react';
import { View, Text, Animated } from 'react-native';

import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require("../theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);

class Expandable extends React.Component {
	
	constructor (props) {
		super(props);
		this.animatedValue = new Animated.Value(0);
		this.state = {
			expanded: props.expanded || false,
			animated: props.animated || false,		// false - used to set initial value
			lastExpanded: null,
			lastAnimated: null,
			size: 18,
			color: null,
			lastSize : null,
			lastColor : null,
		};
		this.resetItem = this.resetItem.bind(this);
	}
	
	// has anything changed?
	static getDerivedStateFromProps(props, state) {
		let changed = false;
		let result = {};
		if (state.expanded !== state.lastExpanded) {
			result.expanded = state.expanded;
			result.lastExpanded = state.expanded;
			changed = true;
		}
		if (state.animated !== state.lastAnimated) {
			result.animated = state.animated;
			result.lastAnimated = state.animated;
			changed = true;
		}

		if (props.size !== state.lastSize) {
			result.size = props.size;
			result.lastSize = props.size;
			changed = true;
		}
		if (props.color !== state.lastColor) {
			result.color = props.color;
			result.lastColor = props.color;
			changed = true;
		}
		return changed ? result : null;
	}
	
	resetItem(all = false) {
		let newstate = {
			animated: false,
		};
		if (all) newstate.expanded = false;
		this.setState(newstate, () => {
			this.animatedValue.setValue(0);			// replace rotate symbol with it's unrotated counterpart
//			this.forceUpdate();
		});		// disable animation until the next onPress event
	}
	
	// animation has played.
	_complete(done) {
		if (done.finished) {
			this.resetItem();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.animated != this.state.animated) {
			this.animatedValue.setValue(0);		// restart animation
			if (this.state.animated) {
				Animated.timing(this.animatedValue, {
					toValue: 1,
					duration: 700,
					useNativeDriver: true,
				}).start((done) => {this._complete(done)});
			}
		}
	}
	
	render() {
		let iconname;
		if (!this.state.animated) {
			iconname = this.state.expanded ? 'circle-up' : 'circle-down';
			return (
				<View {...this.props}>
					<IconMoon name={iconname} size={this.state.size} color={this.state.color} />
				</View>
			);
		}

		const interpolateRotationOpen = this.animatedValue.interpolate({
			inputRange: [0, 1],
			outputRange: ['0deg', '180deg' ],
		});
		const interpolateRotationClose = this.animatedValue.interpolate({
			inputRange: [0, 1],
			outputRange: ['0deg', '-180deg' ],
		});
		
		let rotations = this.state.expanded ? interpolateRotationClose : interpolateRotationOpen;
		iconname = this.state.expanded ? 'circle-down' : 'circle-up';		// these are replaced by correct-orientation characters on end-animation

		return (
			<View {...this.props}>
				<Animated.View style={[{transform: [{rotate: rotations}]}, {alignSelf: 'flex-start'}]}>
					<IconMoon name={iconname} size={this.state.size} color={this.state.color} />
				</Animated.View>
			</View>
		);
	}
}

export default Expandable;