// when leaving a page, but from mobile menu, display warning if not all checkboxes are checked
// display notification, with continue button
// does not fire on change of tab :(
// when moving to next page, and then canceled; navigation believes it is still on the next page.

import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Slider,
  Dimensions,
  Image,
  Platform
} from 'react-native';

import Styles from '../theme/Styles';
import DropdownAlert from 'react-native-dropdownalert';
import {DropDownHolder, DropdownType} from './DropDownHolder';
import Storage from '../components/Storage';
import { withNavigation, NavigationActions } from 'react-navigation';

import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require("../theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);

//  https://github.com/react-navigation/react-navigation/issues/1815

// <WarnOnBlur onBlur="" />
class WarnOnBlur extends Component {
	
	constructor(props) {
		super(props);
		this._checkTheCheckboxes = this._checkTheCheckboxes.bind(this);
		this._displayButton = this._displayButton.bind(this);
		this.enabled = false;
	}
	
	_checkTheCheckboxes(params) {
		let {action, state, result} = params;
//		if (action.type === 'Navigation.COMPLETE_TRANSITION') return;	// arrived at target page

		if (action.type === 'Navigation/NAVIGATE' && this.enabled) {
			let page = this.props.parent;
			let unselected = 0;
			for(let i = 0; i < page.children.length; i++) {
				let node = page.children[i];
				let special = typeof node.special === 'undefined' ? false : node.special;
				if (node.type == 'lineitem' && node.checkbox && !special) {
					var value = Storage.local_getCheckbox(node.hash, false);
					if (!value) unselected++;
				}
			}
			if (0 && unselected > 0) {
				this.routeName = action.routeName;		// save where we want to go
				this.routeParam = action.params;
				result.value = null;					// cancel the navigation
				DropDownHolder.alert('warn', this.props.header, this.props.subheader);
			}
		}
	}
	
	_cancel() {
		DropDownHolder.close();
	}

	_forward() {
		DropDownHolder.close();
		// disable the DropdownAlert, replay navigation
		this.enabled = false;
		this.props.navigation.navigate(this.routeName, this.routeParam);
	}

	// return <CancelButton imageStyle={cancelBtnImageStyle} imageSrc={cancelBtnImageSrc} onPress={() => this.closeAction(ACTION.cancel)} />;
	_displayButton(params) {
		var {props, alertData, result} = params;
		//	{ padding: 8, width: 36, height: 36, alignSelf: 'center' }
		// also have a close link
		// this link will go to the requested page
		let style = {flex: 1, flexDirection: 'row', alignSelf: 'flex-end', alignItems: 'center'};
		let tstyle = {color: '#fff', fontSize: 20};
		let vstyle = {};
		if (Platform.OS === 'ios') {
			vstyle.marginTop = 15;
		}
		result.value = (
			<View>
				<TouchableOpacity style={vstyle} onPress={() => {this._cancel();}}>
					<View style={style}>
						<Text style={tstyle}>Back  </Text>
						<IconMoon name={'cancel-circle'} color={'#fff'} size={22} />
					</View>
				</TouchableOpacity>
				<TouchableOpacity style={vstyle} onPress={() => {this._forward();}}>
					<View style={style}>
						<Text style={tstyle}>OK  </Text>
						<IconMoon name={'circle-right1'} color={'#fff'} size={22} />
					</View>
				</TouchableOpacity>
			</View>
		)
	}
	
	componentDidMount() { 
		this._ismounted = true;
		Storage.on('pageBlur', this._checkTheCheckboxes);
		Storage.on('dropdownButton', this._displayButton);

	}

	componentWillUnmount() {
		Storage.off('pageBlur', this._checkTheCheckboxes);
		Storage.off('dropdownButton', this._displayButton);
		this._ismounted = false;
	}

	// will update: 
		// get list checkboxes

	// this component does generate any output
	render() {
		this.enabled = true;
		return null;
	}
}

export default withNavigation(WarnOnBlur);