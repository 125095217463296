import React, { Component } from 'react';
import {
  View,
  Text,
} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Styles from '../theme/Styles';

export default class StreetAddress extends Component {

  asAddress(lines) {
	  return lines.map(function(value, i){
			if (i) {
				return (
					<View key={i} style={Styles.care_row}><View style={Styles.icon_small_container}></View><Text> {value}</Text></View>
				);
			} else {
				return (
					<View key={i} style={[Styles.care_row, {paddingTop: 10}]}><View style={Styles.icon_small_container}><Icon name={'address-card'} style={Styles.icon_small} size={16} /></View><Text> {value}</Text></View>
				);
			}
	  });
  }

  render() {

    return(
		<View>{this.asAddress(this.props.lines)}</View>
    );
  }

}

