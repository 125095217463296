import React from 'react';
import { Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import background from '../theme/homepage-bg.jpg';
import background_next from '../theme/homepage-next.png';
import Icon from "react-native-vector-icons/FontAwesome5";
import { withNavigation } from "react-navigation";
import { DrawerActions } from 'react-navigation-drawer';

let primaryColor = '#27a2a0';

// color #fff, textAlign: center; fontSize: 24;
export function HeaderBar(navigation, options) {
	let themeoptions = {
	  headerStyle: {
        backgroundColor: primaryColor,
      },
      headerTintColor: '#ffffff',
      headerTitleAlign: 'center', 
      headerTitleStyle: {
        fontSize: 24,
//        fontWeight: 'bold',
        color: '#fff',          // instead of headerTintColor: '#ffffff', which no longer works
      },
      headerLeftContainerStyle: {
        marginLeft: 20,
      },
      headerLeft: () => { 
           return (
            <TouchableOpacity
              onPress={() => {
                navigation.dispatch(DrawerActions.toggleDrawer());
              }}
            >
              <Icon name={"bars"} size={26} color="#fff" />
            </TouchableOpacity>
          )
      },
	  headerTitle: props => {
		  let {width} = Dimensions.get('window');
		  let subheader = null;
		  if (typeof options.subheader !== 'undefined' && options.subheader !== null && width > 440) {
			subheader = (
				<Text style={{color: '#ffda79'}}> — {options.subheader}</Text> 
			);
		  }

		  return (
			<Text {...props} >{props.children}{subheader}</Text>
		  )
	  }
	}
	return Object.assign(options, themeoptions);
};

export function documentTitle(title) {
	document.title = title;
}

export function PerformSharing(content_text, procedurecode) {
    // go to selected website
    let caption = 'PatientApps - ' + content_text.splash.header;
    let link = 'https://' + procedurecode + '.myapp.care/';
    let param = {};
    let url;
    return {
        caption: caption,
        url: link,
    };
}

// https://www.pinterest.com/pin/create/button/?description=ShareThis%3A Free Social Share Buttons %26 Plugins for Websites %26 Blogs&media=https%3A%2F%2Fpcdn.sharethis.com%2Fwp-content%2Fuploads%2F2019%2F06%2Fmobile-browser-screen%402x.jpg&url=https%3A%2F%2Fsharethis.com%2F
// https://twitter.com/intent/tweet?text=ShareThis%3A Free Social Share Buttons %26 Plugins for Websites %26 Blogs&url=https%3A%2F%2Fsharethis.com%2F
// https://www.linkedin.com/shareArticle/?title=ShareThis%3A%20Free%20Social%20Share%20Buttons%20%26%20Plugins%20for%20Websites%20%26%20Blogs&url=https%3A%2F%2Fsharethis.com%2F
// https://www.facebook.com/sharer.php?t=ShareThis%3A Free Social Share Buttons %26 Plugins for Websites %26 Blogs&u=https%3A%2F%2Fsharethis.com%2F

export default StyleSheet.create({
	theme: {
		color: primaryColor,
	},
	h1: {
		fontSize: 22, 
		fontWeight: 'bold',
	},
	h4: {
		fontSize: 20, 
		fontWeight: 'bold', 
		color: '#467f82',
	},
	splash_container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		position: 'relative',
		backgroundImage: `url(${background})`,
		backgroundSize: 'cover',
	},
    // New STUFF ----------------
	splash_inner_frame: {
		top: 10,
		bottom: 10,
		left: 10,
		right: 10,
		flex: 1,
		justifyContent: 'center',
		alignItems: 'stretch',
		backgroundColor: 'rgba(89, 160, 207, 0.3)',
		position: 'absolute',
	},
    
    // old STUFF ----------------
	t_title: {
		color: '#fff',
		fontWeight: '500',
		textAlign: 'center',
	},
	t_subheader: {
		paddingTop: '1%',
		color: '#fff',
		fontWeight: '400',
		fontSize: 24,
		textAlign: 'center',
	},
	version: {
		color: '#fff',
		fontSize: 14,
		textAlign: 'center',
	},
    // --------------------------
	page_container: {
		flex: 1,
		paddingTop: 15,
		paddingLeft: 15,
		paddingRight: 15,
		backgroundColor: '#eef',
		flexDirection: 'column',
	},
	page_content: {
		alignSelf: 'center',
	},
	care_container: {
		backgroundColor: primaryColor,
		borderRadius: 5,
		flexDirection: 'row',
		padding: 5,
		marginTop: 7,
		marginBottom: 7,
	},
	care_icon: {
		width: 60,
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		paddingRight: 5,
	},
	icon_small_container: {
		width: 20,
	},
	icon_small: {
	},
	care_items: {
		backgroundColor: '#fff',
		borderRadius: 5,
		padding: 10,
	},
	care_header: {
		fontWeight: '600',
		fontSize: 18,
	},
	care_row: {
	  flex: 1,
	  flexDirection: 'row',
	},
	dropDownContainer: {
		marginTop: 3,
		marginBottom: 3,
	},
	dropdownTop: {	//238
		backgroundColor: '#eeeeee',
		paddingTop: 7,
		paddingBottom: 7,
		paddingLeft: 15,
		paddingRight: 15,
	},
	dropdownBody: {
		backgroundColor: '#eeeeee',
		paddingLeft: 15,
		paddingRight: 15,
	},
	dropdownHilite: {
		backgroundColor: '#FFEAF4',
	},
	dropdownHilite2: {
		backgroundColor: '#C6FFFE',
	},
	dropdownTop2: {	//238
		backgroundColor: '#f8f8f8',
		paddingTop: 7,
		paddingBottom: 7,
		paddingLeft: 15,
		paddingRight: 15,
	},
	dropdownBody2: {
		backgroundColor: '#f8f8f8',
		paddingLeft: 15,
		paddingRight: 15,
	},
	dropdownContent: {
		flex: 1,
		flexDirection: 'column',
	},
	pagelink: {
		backgroundColor: '#eeeeee',
		paddingLeft: 15,
		paddingRight: 15,
		marginTop: 3,
		marginBottom: 3,
		paddingTop: 7,
		paddingBottom: 7,
		alignSelf: 'stretch',
		flexDirection: 'row', 
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flex: 1, 
	},
	linkiconLeft: {
		alignSelf: 'center',
	},
	linkBody: {
		alignSelf: 'center',
		flex: 1,
		paddingLeft: 10,
	},
	linkiconRight: {
		alignSelf: 'center',
	},

	pagearrow: {
		position: 'absolute',
		right: 0,
		paddingTop: 7,
		paddingBottom: 7,
	},
	tabbar: {
		marginBottom: 20,
		flex: 1,
		flexDirection: 'row',
	},
	tabbutton: {
		paddingLeft: 10,
		paddingRight: 10,
	},
	tabtextcontainer: {
		borderColor: '#a1a1a1',
		backgroundColor: '#fff',
		borderWidth: 1,
		width: '100%',
	},
	tabtextcontainerActive: {
		backgroundColor: '#a1a1a1',
	},
	tabtext: {
		paddingTop: 5,
		paddingBottom: 5,
		fontSize: 22,
		color: '#999',
		backgroundColor: '#fff',
		alignSelf: 'center',
	},
	tabtextActive: {
		color: '#fff',
		backgroundColor: '#a1a1a1',
	},
	lineitemContainer: {
	  flex: 1,
	  flexDirection: 'row',
	},
	checkboxnote: {
		color: '#d00',
		paddingLeft: 60,
		marginBottom: 5,
	},
	listitem: {
		justifyContent: 'flex-start',
		paddingTop:3, 
		paddingBottom:3, 
		paddingLeft: 10, 
		flex: 1, 
		flexDirection: 'row',
		alignItems: 'flex-start',
	},	
	menuItemHeader: {
		marginTop: 5,
		backgroundColor: '#999',
		paddingLeft: 10,
		paddingTop: 5,
		paddingBottom: 5,
	},
	menuItem: {
		backgroundColor: '#eef8fd',
		paddingLeft: 10,
		paddingTop: 5,
		paddingBottom: 5,
		flex: 1,
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
	menuTextHeader: {
		color: '#fff',
		fontSize: 18,
	},
	menuText: {
		fontSize: 16,
		paddingLeft: 10,
	},
	centeredContent: {
//		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	playerContainer: {
//		borderColor: '#900',
//		borderWidth: 1,
	},
	menuPanel: {
		paddingTop: 20,
		backgroundColor: '#eef8fd',
	},
	f_head: {
		color: primaryColor,
		fontSize: 20,
		fontWeight: 'bold',
		marginTop: 5,
		marginBottom: 5,
	},
	f_text: {
		fontSize: 16,
	},
	f_table: {
		flex: 3,
		flexDirection: 'row',
		alignItems: 'center',
	},
	f_cell: {
		alignItems: 'center',
		flex: 1,
		flexDirection: 'column',
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 10,
		marginBottom: 10,
	},
	f_image: {
		width: 64,
		height: 64,
		resizeMode: 'stretch',
	},
	f_input: {
		borderColor: '#999',
		borderWidth: 1,
		marginTop: 10,
		marginBottom: 15,
		padding: 5,
	},
	f_right: {
		flex: 1,
		justifyContent: 'flex-end',
		flexDirection: 'row',
	},
	f_button: {
		margin: 20,
	},
	f_buttoncolor: {
		color: primaryColor,
	},
	pageContainer: {
		margin: 20, 
		padding:20, 
		backgroundColor: 'rgba(255,255,255,.8)', 
		alignSelf: 'center', 
		opacity: 0.95,
	},
	calendarNav: {
		borderColor: '#a1a1a1', 
		borderWidth: 1, 
		margin: 2, 
		flex: 1, 
		padding: 5, 
		flexDirection: 'column', 
		justifyContent: 'center',
	},
	divider: {
		borderTopColor: primaryColor,
		borderBottomColor: '#000',
		borderWidth: 1,
		marginTop: 5,
		marginBottom: 5,
		opacity: 0.3
	},
    settingIcon: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'flex-end',
        position: 'absolute',
        bottom: 0, 
        paddingRight: 7,
        paddingBottom: 7,
    },
    image: {
    },
    image_clickable: {
        cursor: 'pointer',
    },

});
