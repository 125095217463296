import React, { Component } from 'react';

import { View, Platform } from 'react-native';
import uuidv4 from '../components/uuid';
import Storage from '../components/Storage';
import ProcedureData from '../components/ProcedureData';

// what page is being viewed
//   send directly to website
// params:
//   uuid: the random Install ID (unrelated to the device being used)
//   product id
//   page
//   date/time of access

// https://patientapps.net/api/check-product?pc=localhost&version=1.9.28&app=web&_=1612914061

class Watch extends Component {

	constructor(props) {
		super(props);
    }

    componentDidUpdate(prevProps) {
        if(this.props.page != prevProps.page) {
            this.performChange();
        }
    } 

    performChange() {
        let param = {
            page:    this.props.page,
            et:      'view',
        };
        ProcedureData.SendResults(param);
    }

    static registerEvent(pagenum, eventtype) {
        let param = {
            page:    pagenum,
            et:      eventtype,
        };
        ProcedureData.SendResults(param);
    }

	render() {
/*        if (this.props.singlepage) {
            let now = new Date();
            let param = {
                page:    this.props.singlepage,
                ts:      parseInt(now / 1000),
                et:      'view',
            };
            ProcedureData.SendResults(param);
        } */
        return (
            <View />
        );
    }
    
}

export default Watch;
