// NavigationService.js

import Storage from '../components/Storage';
import ProcedureData from '../components/ProcedureData';

let _drawer;

if (typeof global.current_page == 'undefined') {
	global.current_page = 1;
	global.active = { tab: {}, dropdown: {}} ;		// what tabs or dropdowns are active on a page
}

function getCurrentPage() {
	return global.current_page;
}
function setCurrentPage(value) {
	if (value == -999) {
		value = ProcedureData.getFirstPage();
	}
	global.current_page = value;
	if (typeof _drawer !== 'undefined') {
		_drawer.setState({page: value});
	}
	return value;
}

function setDrawer(value) {
	_drawer = value;
}

// ----------------------------------------------------------------------

function init() {

}

// scan for the first possible tab on the page
function _scanForTab(page) {
	if (page.type == 'tab') {
		return page.hash;
	}
	if (typeof page.children !== 'undefined') {
		for(let i = 0; i < page.children.length; i++) {
			let hash = _scanForTab(page.children[i]);
			if (hash !== false) return hash;
		}
	}
	return false;
}

function getActiveTab(pagehash) {
	// if the value is empty, return the hash of the first tab on the page
	if (!global.active.tab.hasOwnProperty(pagehash)) {
		let page = ProcedureData.getPageData(ProcedureData.getPageFromHash(pagehash) + 1);
		let firsttab = _scanForTab(page);
		setActiveTab(pagehash, firsttab);
	}
	return global.active.tab[pagehash];
}

function getActiveDropdown(hash) {	// pagehash or tabhash
	// will return empty if no active dropdown
}

function setActiveTab(pagehash, tabhash) {
	global.active.tab[pagehash] = tabhash;
}

function setActiveDropdown(hash, listitemhash) {	// hash: pagehash or tabhash
}

function clear() {
	global.current_page = 1;
	global.active = { tab: {}, dropdown: {}} ;		// what tabs or dropdowns are active on a page
}

// ----------------------------------------------------------------------

export default {
  init,
  getCurrentPage,
  setCurrentPage,
  getActiveTab,
  getActiveDropdown,
  setActiveTab,
  setActiveDropdown,
  setDrawer,
  clear,
};