
// Get json, images and audio from patientapps.net
//  replaces resource names with urls to the online version

// web version also extracts the procedure code from the url

import React from 'react';
import Storage from '../components/Storage';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import {findNodeHandle} from 'react-native';
import GlobalSettings from '../components/GlobalSettings';

let _detectedProduct = null;
let preview = 0;

function init() {

}

function getPreviewMode() {
	return preview;
}


function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function determineProduct() {
	return new Promise((resolve, reject) => {
		let loc = window.location.href;

		let r = new RegExp('^(?:[^:]+://)?([^/]+)/([^/]+)');
		let matches = loc.match(r);
		let nameandport = matches[1];
		let components = nameandport.split(':');
		let domainname = components[0];
		let elements = domainname.split('.');
		if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(domainname)) {
			_detectedProduct = null;
		} else {
			_detectedProduct = elements[0];
		}
		let param = getParameterByName('preview', loc);
		if (param) {
			preview = parseInt(param);
            if (preview) {
                console.log('Preview Mode enabled');
            }
		}
		let product = getParameterByName('procedure', loc);
		if (product !== null) {
			_detectedProduct = null;
			Storage.trigger('cleardata', {override: product});
			resolve(true);
			return;
		}
		resolve(true);
	});
}

// getter/setter
function detectedProduct() {
	if (arguments.length) {
		_detectedProduct = arguments[0];
	}
	return _detectedProduct;
}

function getImageData(selected_procedure, imagename, content_type) {
	let api_url = Storage.getAPIurl(preview);
	return Promise.resolve({
		uri: api_url + '/content/' + selected_procedure + '/' + imagename + '?type=' + content_type,
		name: imagename,
	});

}

// master download location
function generateDownloadUrl(selected_procedure) {
	let version = encodeURIComponent(Constants.manifest.version);
	let api_url = Storage.getAPIurl(preview);

	// version for web will only be the main json file.  Images will not be included
	return {
		url: api_url + '/download/' + selected_procedure + '.json?version=' + version + '&app=' + Platform.OS
	};
}

function saveDownload(responseText) {
	let jsondata = JSON.parse(responseText);
	for(let key in jsondata) {
		let data = jsondata[key];
		Storage.local_setValue('.' + key, data);
	}
}

function javascriptEvent(eventName, eventData) {
    if (eventData.container) {
        eventData.container = findNodeHandle(eventData.container);
    }
	let event = new CustomEvent(eventName, {
		cancelable: true,
		detail: eventData
	});
	return document.dispatchEvent(event);
}

// open url in same page as app  (Linking will open in new tab)
function openUrl(url) {
    location.href = url;
}


export default {
  init,
  determineProduct,
  detectedProduct,
  getPreviewMode,
  getImageData,
  generateDownloadUrl,
  saveDownload,
  javascriptEvent,
  openUrl,
};