// Download the file needed for the procedure; images, json
// Audio requires internet connection since it is downloaded only when needed.

import React, { Component } from 'react';
import * as FileSystem from 'expo-file-system';
import { Text, View, TouchableOpacity, Dimensions, Platform } from 'react-native';
import Storage from '../components/Storage';
import Frame from '../components/Frame';
import Styles from '../theme/Styles';
import Constants from 'expo-constants';
import AnimatedProgressWheel from '../components/AnimatedProgressWheel';
import Resource from '../components/Resource';

const fetchData = (url, progress) => {
	return new Promise((resolve, reject) => {
		var oReq = new XMLHttpRequest();
		oReq.addEventListener('progress', progress);
		oReq.open('GET', url);
		oReq.send();
		oReq.onreadystatechange = function() {
			if (oReq.readyState == XMLHttpRequest.DONE) {
				resolve(oReq.responseText);
			}
		}
	});
}


class ProcedureDownload extends Component {

	state = {
        progress: 0,
    };
	
	constructor(props) {
		super(props);
		this.circleProgress3 = React.createRef();
		this._isMounted = false;
		this.updateProgress = this.updateProgress.bind(this);
	}

	// start download, if not already present
	componentDidMount() {
		if (!this._isMounted) {
			this._isMounted = true;
			this._startLoadingAppResourcesAsync().catch(error => {
				console.error('we have a problem', error);
			});
		}
	}
	
	componentWillUnmount() {
        this._isMounted = false;
    }
	
	_saveJson(json) {
		for(let key in json) {
			let data = json[key];
			Storage.local_setValue('.' + key, data);
		}
	}
	
	static clear(clearall = true) {
		// clear downloaded images and json
		for(let key in global.settings) {
			if (key.substring(0, 1) === '.') {
				Storage.local_removeValue(key);
			}
		}
		// clear the currently selected procedure
		if (clearall) {
			Storage.local_removeValue('procedure');
			Storage.local_removeValue('location');
		}
		// clear master.json
	}

	updateProgress (oEvent) {
		if (oEvent.lengthComputable) {
			var progress = Math.ceil(oEvent.loaded / oEvent.total * 100);
			this.setState({progress});
			if (typeof this.circleProgress3.current !== 'undefined' && this.circleProgress3.current !== null) {
				this.circleProgress3.current.animateTo(progress);
			}
		} else {
		// Unable to compute progress information since the total size is unknown
		}
	}
	
	async ondownloaded(response) {
		ProcedureDownload.clear(false);
		await Resource.saveDownload(response);
		this.setState({progress: 100});
		if (typeof this.circleProgress3.current !== 'undefined' && this.circleProgress3.current !== null) {
			this.circleProgress3.current.animateTo(100);
		}
		// save files
		this.props.onFinish();
	}
	
	_startLoadingAppResourcesAsync = async () => {
		try {
			let selected_procedure = Storage.local_getValue('procedure', null);
			let config = Resource.generateDownloadUrl(selected_procedure);
			if (typeof config.url !== 'undefined') {
				await fetchData(config.url, this.updateProgress)
				.then((response) => {
					this.ondownloaded(response);
					return 1;
				}).catch((error) => {
					// unable to download - try again, is device connected to internet?
					console.log('error:', error);
					return 0;
				});
			} else {
				config.promise.then(({ uri }) => {
					this.ondownloaded(null);
				});
			}
		}
		catch (e) {
			if (!this._isMounted)
				return;
			if (this.props.onError) {
				this.props.onError(e);
			} else {
				throw e;
			}
		}
		finally {
			if (!this._isMounted)
				return;
		}
	}

	render() {
// display status screen - maybe with progressbar?
		return (
			<Frame>
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <AnimatedProgressWheel ref={this.circleProgress3} size={200} width={24}
                                           progress={this.state.progress} color={'#c0e4f8'}
                                           style={{marginBottom: 30}} />
                </View>
				<View><Text style={[Styles.t_subheader, {fontSize: 24} ]}>Please wait while the content for this procedure is downloaded</Text></View>
			</Frame>
		);
	}
}

export default ProcedureDownload;
