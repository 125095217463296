'use strict'

import React, { Component } from 'react';
import { Text, View, TouchableOpacity, TouchableWithoutFeedback, LayoutAnimation, Platform, UIManager, StyleSheet, Image} from 'react-native';

// based loosely on https://github.com/moschan/react-native-simple-radio-button
// Used by Feedback

var Style = StyleSheet.create({

  radioWrap: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  radio: {
    justifyContent: 'center',
    alignItems: 'center',

    width: 30,
    height: 30,

    alignSelf: 'center',

    borderColor: '#2196f3',
    borderRadius: 30,
  },

  radioLabel: {
    paddingLeft: 10,
    lineHeight: 20,
  },

  radioNormal: {
    borderRadius: 10,
    width: 20,
    height: 20,
  },

  radioActive: {
    width: 20,
    height: 20,
    backgroundColor: '#2196f3',
  },

  labelWrapStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center'
  },

  labelVerticalWrap: {
    flexDirection: 'column',
    paddingLeft: 10,
  },

  labelHorizontalWrap: {
    flexDirection: 'row',
  },
  
  labelVertical: {
    paddingLeft: 0,
  },

  formHorizontal: {
    flexDirection: 'row',
  },
});


export default class RadioForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_active_index: props.initial
    }
    if (Platform.OS === 'android') {
      UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    this._renderButton = this._renderButton.bind(this);
  }
  static defaultProps = {
    radio_props: [],
    initial: 0,
    labelHorizontal: false,
    animation: true,
    buttonColor: '#2196f3',
    selectedButtonColor: '#2196f3',
    labelColor: '#000',
    selectedLabelColor: '#000',
    wrapStyle: {},
  }


  buttonSelected(value, index, obj) {
	  if (typeof this.props.obj !== 'undefined') {
		  if (this.state.is_active_index == index) {
			  index = -1;
		  }
	  }
	  this.props.onPress(value, index, obj);
	  this.setState({is_active_index: index});
  }

  _renderButton(obj, i) {
    return (
      <RadioButton
        accessible={this.props.accessible}
        accessibilityLabel={(this.props.accessibilityLabel)
          ? (this.props.accessibilityLabel + '|' + i) : ('radioButton' + '|' + i)}
        isSelected={this.state.is_active_index === i}
        obj={obj}
        key={i}
        index={i}
        buttonColor={this.state.is_active_index === i ? this.props.selectedButtonColor : this.props.buttonColor}
        buttonSize={this.props.buttonSize}
        buttonOuterSize={this.props.buttonOuterSize}
        labelHorizontal={this.props.labelHorizontal}
        labelColor={this.state.is_active_index === i ? this.props.selectedLabelColor : this.props.labelColor}
        labelStyle={this.props.labelStyle}
		imageStyle={this.props.imageStyle}
        style={this.props.radioStyle}
        animation={this.props.animation}
        onPress={(value, index) => {
			this.buttonSelected(value, index, obj);
        }}
      />
    )
  }

  render() {
    var render_content = this.props.radio_props.map(this._renderButton);
    return (
      <View style={this.props.style}>
        {render_content}
      </View>
    );
  }
}

export class RadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  static defaultProps = {
    isSelected: false,
    buttonColor: '#2196f3',
    selectedButtonColor: '#2196f3',
    labelHorizontal: true,
    disabled: false,
    idSeparator: '|'
  }
  UNSAFE_componentWillUpdate () {
    if (this.props.animation) {
      LayoutAnimation.spring();
    }
  }
  render () {
    var idSeparator = (this.props.idSeparator) ? this.props.idSeparator : '|';
    var idSeparatorAccessibilityLabelIndex = (this.props.accessibilityLabel)
					? this.props.accessibilityLabel.indexOf(idSeparator) : -1;

    var accessibilityLabel = (this.props.accessibilityLabel)
					? (idSeparatorAccessibilityLabelIndex !== -1
					? this.props.accessibilityLabel.substring(0, idSeparatorAccessibilityLabelIndex) : this.props.accessibilityLabel) : 'radioButton';

    var accessibilityLabelIndex = (this.props.accessibilityLabel && idSeparatorAccessibilityLabelIndex !== -1)
					? this.props.accessibilityLabel.substring(idSeparatorAccessibilityLabelIndex + 1) : '';

    return (
      <View style={this.props.style}>
        <RadioButtonInput {...this.props}
          accessibilityLabel={accessibilityLabel + 'Input' + accessibilityLabelIndex} />
        <RadioButtonLabel {...this.props}
          accessibilityLabel={accessibilityLabel + 'Label' + accessibilityLabelIndex} />
      </View>
    )
  }
}

export class RadioButtonInput extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isSelected: false,
	  buttonColor: props.buttonColor || '#2196f3',
    };
  }

    _asRadioButton () {
		var innerSize = {width: 20, height:20, borderRadius: 20/2 }
		var outerSize = {width: 20+10, height:20+10, borderRadius: (20+10)/2 }
		if (this.props.buttonSize) {
		  innerSize.width = this.props.buttonSize
		  innerSize.height = this.props.buttonSize
		  innerSize.borderRadius = this.props.buttonSize /2
		  outerSize.width = this.props.buttonSize + 10
		  outerSize.height = this.props.buttonSize + 10
		  outerSize.borderRadius = (this.props.buttonSize + 10) / 2
		}
		if (this.props.buttonOuterSize) {
		  outerSize.width = this.props.buttonOuterSize
		  outerSize.height = this.props.buttonOuterSize
		  outerSize.borderRadius = this.props.buttonOuterSize / 2
		}
		var outerColor = this.props.buttonOuterColor
		var borderWidth = this.props.borderWidth || 3
		var innerColor = this.props.buttonInnerColor
		if (this.props.buttonColor) {
		  outerColor = this.props.buttonColor
		  innerColor = this.props.buttonColor
		}
		var radioStyle = [
		  Style.radio,
		  {
			borderColor:outerColor,
			borderWidth:borderWidth
		  },
		  this.props.buttonStyle,
		  outerSize
		];
		return {
			jsx: (
			  <View style={[
				Style.radioNormal,
				this.props.isSelected && Style.radioActive,
				this.props.isSelected && innerSize,
				this.props.isSelected && {backgroundColor:innerColor}
			  ]}></View>
			),
			style: radioStyle,
		}

    }
  
  render () {
	var c;
	var radioStyle = {};
	if (typeof this.props.obj.resource0 !== 'undefined') {
		if (this.props.isSelected) {
			c = (
				<Image style={this.props.imageStyle} source={this.props.obj.resource1} />
			)
		} else {
			c = (
				<Image style={this.props.imageStyle} source={this.props.obj.resource0} />
			)
		}
	} else {
		let info = this._asRadioButton();
		c = info.jsx;
		radioStyle = info.style;
	}

    return (
      <View style={this.props.buttonWrapStyle} >
        <TouchableOpacity
          accessible={this.props.accessible}
          accessibilityLabel={this.props.accessibilityLabel}
		  style={radioStyle}
          onPress={() => { this.props.onPress( this.props.obj.value, this.props.index) }
          }>
         {c}
        </TouchableOpacity>
      </View>
    );
  }
}

RadioButtonInput.defaultProps = {
  buttonInnerColor: '#2196f3',
  buttonOuterColor: '#2196f3',
  disabled: false
}
export class RadioButtonLabel extends React.Component {
  render () {
    return (
      <TouchableWithoutFeedback
        accessible={this.props.accessible}
        accessibilityLabel={this.props.accessibilityLabel}
        onPress={() => {
          this.props.onPress( this.props.obj.value, this.props.index);}
      }>
        <View style={[
          this.props.labelWrapStyle,
          Style.labelWrapStyle,
        ]} >
          <Text style={[
            Style.radioLabel,
            this.props.labelHorizontal && Style.labelHorizontal,
            {color: this.props.labelColor},
            this.props.labelStyle
          ]}>{this.props.obj.label}</Text>
        </View>
      </TouchableWithoutFeedback>
    )
  }
}
