import React, { Component } from 'react';
import Storage from '../components/Storage';
import base64 from 'base-64';
import DefaultPage from '../theme/default-page.json';
import Emergency from '../components/Emergency';
import GlobalSettings from '../components/GlobalSettings';
import Resource from '../components/Resource';
import uuidv4 from '../components/uuid';
import { Platform } from 'react-native';
import Constants from 'expo-constants';

let content_text = false;
let image_ticker = 1;
let first_time = true;
let online_state = 'none';

let _uuid = false;
let _product = false;

function init() {
	try {
		if (content_text == false) {
			let selected_json = Storage.local_getValue('.data.json', false);
			if (selected_json === false || selected_json === null) {
				content_text = {content: []}
			} else {
				content_text = JSON.parse(base64.decode(selected_json));
				_addParentLinks();
			}
		}
	} catch (e) {
		console.log('exception', e);
	}
}

function cleardata(param) {
	Storage.local_removeValue('.data.json');		// remove local copy of pai-.data.json - so that it can be redownloaded
	Storage.local_removeValue('master.json');		// remove product catalog
	Storage.local_setValue('introduction', '1');	// show the introductory pages
	if (param.override) {
		Storage.local_setValue('procedure', param.override);
	}
	delete global.master;
    _product = false;
	clear();
}

if (first_time) {
	Storage.on('cleardata', cleardata);
	first_time = false;
}

function _processNode(node, parent) {
	node['.parent'] = parent;
	if (node.children) {
		for(let i = 0; i < node.children.length; i++) {
			_processNode(node.children[i], node);
		}
	}
	if (node.type === 'lineitem' && node.image) {
		_processNode(node.image, node);
	}
}

function _addParentLinks() {
	for(let page = 0; page < content_text.content.length; page++) {
		let node = content_text.content[page];
		_processNode(node, null);
	}
}


function macro(text, astext) {
	var needle, reg;
	init();
	
	text = text.replace(/\{\{(.*?)\}\}/g, (original, key) => {
		if (typeof content_text.macro[key] !== 'undefined') {
			return content_text.macro[key];
		}
		let val = Storage.local_getValue(key, false);
		if (val !== false) {
			key = key + '.' + val;
			if (typeof content_text.macro[key] !== 'undefined') {
				return content_text.macro[key];
			}
		}
		return '';
	});
	return text;
}

// return a json object for the current procedure
function contentText() {
	init();
	return content_text;
}

function getNextPage(delta, nextpage) {
	init();
	if (typeof nextpage == 'undefined') nextpage = global.current_page;
	var numpages = content_text.content.length;
	var ok;
	do {
		ok = true;
		if (delta < 0) {
			if (nextpage > 1) {
				nextpage--;
			} else {
				nextpage = numpages;
			}
		} else {
			if (nextpage < numpages) {
				nextpage++;
			} else {
				nextpage = 1;
			}
		}
		// if previous page is Header, with no content: then not ok - Header page + following Page page are combined.
		if (nextpage > 1 && content_text.content[nextpage - 2].type === 'header' && typeof content_text.content[nextpage - 2].children == 'undefined') {
			ok = false;
		}
	} while (!ok);
	return nextpage;
}

// locate the Table of Contents page
function getTocPage() {
	init();
	var numpages = content_text.content.length - 1;
	for(let i = 0; i <= numpages; i++) {
		let istoc = typeof content_text.content[i].is_toc != 'undefined' ? content_text.content[i].is_toc : false;
		if (istoc) return i;
	}
	return 0;
}

function getTargetIcon(hash) {
	let i = getPageFromHash(hash);
	let content = content_text.content[i]
	return (typeof content.icon === 'undefined') ? undefined : content.icon;
}

function getPageFromHash(hash, default_value = 0) {
	var numpages = content_text.content.length - 1;
	for(let i = 0; i <= numpages; i++) {
		let pagehash = typeof content_text.content[i].hash != 'undefined' ? content_text.content[i].hash : '';
		if (pagehash === hash) return i;
	}
	return default_value;
}

// pagenum is from 1 onwards..
function getPageData(pagenum) {
	init();
	if (pagenum < 1) pagenum = 1;
	var current_page = content_text.content[pagenum - 1];
	if (typeof current_page == 'undefined') {
		current_page = DefaultPage[0];
	}
	if (current_page.type === 'header' && typeof current_page.children === 'undefined') {
		current_page = content_text.content[pagenum];
	}
	current_page.pagenum = pagenum;
	return current_page;
}

function getPageTitle(pagenum) {
	let result = {};
	let page = getPageData(pagenum);
	result.title = page.title;
	if (typeof page.alternate !== 'undefined') {
		let x = macro(page.alternate, true);
		if (x != '') result.subheader = x;
	}
	return result;
}

function getAudio() {
	init();
	return content_text.audio;
}

// country from master.json, selected country, or default to USA
// also come back here when country selection has changed
function setEmergencyNumber() {
	init();
	let product = Storage.local_getValue('procedure', null);
	let countrycode = Storage.local_getValue('country', 'us');
	let productinfo = GlobalSettings.getProductInfo(product);

	// only if generic - since hospital specific guides will have the appropriate emergency number
	if (productinfo.generic > 0) {
		let emergency = Emergency.retrieveList();
		if (typeof emergency[countrycode] !== 'undefined') {
			let temp = emergency[countrycode].split(',');
			for(let i=0; i < temp.length; i++) {
				temp[i] = temp[i].trim();
			}
			content_text.macro.emergency = temp.join(' or ');		// English
		} else {
			content_text.macro.emergency = '911';
		}
	}
}

// Get the first Introductory Page, or Table of Contents
function getFirstPage() {
	// go to introduction, or table of contents
	// if intro, clear Show Intro flag
	let flag = Storage.local_getValue('introduction', '0');
	if (flag == '0') {
		return getTocPage() + 1;
	}
	Storage.local_setValue('introduction', '0');
	return 1;
}

//  get image info: return dimensions. data returned via a Promise
function getImageInfo(imagename) {
	let result = null;
	init();
	for(let i = 0; i < content_text.images.length; i++) {
		if (content_text.images[i].name == imagename) {
			result = content_text.images[i];
			break;
		}
	}
	return result;
}

function setDisplayWidth(imagename, display_width) {
	init();
	for(let i = 0; i < content_text.images.length; i++) {
		if (content_text.images[i].name == imagename) {
			content_text.images[i].current_width = display_width;
			break;
		}
	}
}

function getImagePromise(imagename) {
	let resource = getImageInfo(imagename);
	if (resource === null) return null;
	let selected_procedure = Storage.local_getValue('procedure', null);
	return Resource.getImageData(selected_procedure, imagename, resource.type);	// Promise: assume the image is not yet available
}

function clear() {
	content_text = false;
}

function getCurrentInfo() {
	init();
	let result = {
		appname: content_text.splash.appname,
		version: content_text.splash.version,
	};
	return result;
}

function hasCareTeam() {
    init();
    return content_text && content_text.careteam && content_text.careteam.length;
}

function getExpiry() {
    init();
    if (content_text && content_text.expiry) {
        return content_text.expiry;
    } else {
        return 0;
    }
}


function loadQueuedImages(component) {
	if (typeof component.images === 'undefined') {
		component.images = {};
	}
	let subimage, promise, alternatives;
	for(let imagename in component.images) {
		if (component.images[imagename] === false) {		// image waiting to be loaded
			// see if there are Banner images
			// all alternative images are loaded from the database,
			// then the appropriate image is drawn on render.
			alternatives = false;
			subimage = imagename + '.0';
			promise = getImagePromise(subimage);
			if (promise !== null) {
				alternatives = true;
				component.images[subimage] = promise;
				promise.then( (src) => {
					component.images[subimage] = src.uri;
					image_ticker++;
					component.setState({
						imageLoaded: imagename, 
						imageTicker: image_ticker,
						subimage:	subimage,
					});
				});
			}
			subimage = imagename + '.1';
			promise = getImagePromise(subimage);
			if (promise !== null) {
				alternatives = true;
				component.images[subimage] = promise;
				promise.then( (src) => {
					component.images[subimage] = src.uri;
					image_ticker++;
					component.setState({
						imageLoaded: imagename, 
						imageTicker: image_ticker,
						subimage:	subimage,
					});
				});
			}
			
			if (alternatives) {								// this image has alternatives - so the main image is not to be used.
				component.images[imagename] = null;
				continue;
			}

			let promise = getImagePromise(imagename);
			if (promise !== null) {
				component.images[imagename] = promise;
				promise.then( (src) => {
					component.images[imagename] = src.uri;
					image_ticker++;
					component.setState({
						imageLoaded: imagename, 
						imageTicker: image_ticker,
						subimage: false,
					});
				});
			} else {
				component.images[imagename] = null;			// not found
			}

		}
	}
}

function getQueuedImage(component, resource) {
	// 1px x 1px transparent png
	let imagedata = {uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='};
	let imagename = resource.name;
	if (typeof component.images[imagename] === 'undefined') {
		component.images[imagename] = false;		// signal for it to be loaded
	} else if (typeof component.images[imagename] === 'string') {
		imagedata = {uri: component.images[imagename]};
	}
	return imagedata;
}

// clear out all loaded images
function clearQueuedImages(component) {
	for(let imagename in component.images) {
		if (typeof component.images[imagename] === 'string') {
			delete component.images[imagename];
		}
	}
}

function OnlineState() {
    if (arguments.length) {
        online_state = arguments[0];
    }
    return online_state;
}

// upload the current transaction
function SendResults(param, postdata = null) {
    if (_uuid === false) {
        _uuid = Storage.local_getValue('uuid', '');
		if (_uuid == '') {
			_uuid = uuidv4();
			Storage.local_setValue('uuid', _uuid);
		}
    }
    if (_product === false) {
        _product = Storage.local_getValue('procedure', null);
    }
    
    param.uuid    = _uuid;
    param.product = _product;
    param.source  = Platform.OS;
    if (typeof param.ts == 'undefined') {
        let now = new Date();
        param.ts = parseInt(now / 1000);
    }

    let enableTracking = true;  // default to enabled if missing from the data
    if (content_text && content_text.splash) {
        param.version = Constants.manifest.version + '-' + content_text.splash.version;
        if (typeof content_text.splash.tracking !== 'undefined') {
            enableTracking = content_text.splash.tracking;
        }
    }
    if (enableTracking) {
        let params = '';
        for(let i in param) {
            params += (params != '' ? '&' : '?') + encodeURIComponent(i) + '=' + encodeURIComponent(param[i]);
        };
        let method = postdata === null ? 'GET' : 'POST';
        let payload = {
            method: method,
            headers: {
                Accept:         'application/json',
                'Content-Type': 'application/json',
            },
        };
        if (method == 'POST') {
            payload.body = JSON.stringify(postdata);
        }
        let api_url = Storage.source(0);
        fetch(api_url + '/watch' + params, payload);
    }
}

function getYear() {
    return new Date().getFullYear();
}

function productDetails() {
    let content_text = contentText();
    let myversion = Constants.manifest.version + '-' + content_text.splash.version;
    let url_api = Storage.getAPIurl();
    let expiry = getExpiry();
    let now = parseInt(new Date() / 1000);
    let note = false;
    // show an information message when less than 7 days
    if (expiry > 0) {
        let minutes = parseInt((expiry - now + 59) / 60);
        if (minutes < 0) minutes = 0;
        if (minutes < 120) {
            note = minutes + ' minutes';
        } else if (minutes <= 1440 * 2) {
            note = parseInt((minutes + 59) / 60) + ' hours';
        } else if (minutes <= 1440 * 7) {
            note = parseInt((minutes + 1439) / 1440) + ' days';
        }
    }
    if (url_api.indexOf('beta') > -1) myversion += ' (beta)';

    // this will be based on language
    let text_header = 'The Patient’s Guide™';
    let text_version = 'Version: %s';
    let text_continue = 'Continue';
    if (content_text.splash.text_header) {
        text_header = content_text.splash.text_header;
        text_version = content_text.splash.text_version;
        text_continue = content_text.splash.text_continue;
    }

    return {
        text_header: text_header,
        text_version: text_version.replace('%s', myversion),
        text_continue: text_continue,
        title:  content_text.splash.short,
        tagline: content_text.splash.subheader,
        version: myversion,   // not currently used
        gratis:  note,
        copyright: '© ' + getYear(),
        // authors?
        // logo
        // language(s)
    };

}

export default {
	macro,
	contentText,
	setEmergencyNumber,
	getCurrentInfo,
	getNextPage,
	getTocPage,
	getTargetIcon,
	getPageFromHash,
	getPageData,
	getPageTitle,
	getFirstPage,
	getImageInfo,
    getExpiry,
	setDisplayWidth,
	getAudio,
    hasCareTeam,
	clear,
	loadQueuedImages,
	clearQueuedImages,
	getQueuedImage,
    OnlineState,
    SendResults,
    productDetails,
}

