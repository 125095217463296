import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Text, View} from 'react-native';
import HyperLink from '../components/HyperLink';

import Styles from '../theme/Styles';
import Icon from 'react-native-vector-icons/FontAwesome5';

export default class ItemList extends Component {

	//to do: shorten email address on Phone/Portrait

	render() {
		switch(this.props.type) {
			case 'email':
				return (
					<View>
						<View style={[Styles.care_row, {paddingTop: 4}]}>
							<View style={Styles.icon_small_container}>
								<Icon name={'envelope'} style={Styles.icon_small} size={16} />
							</View>
							<Text> Email: <HyperLink url={this.props.target} title={this.props.value}/> {this.props.extra}</Text>
						</View>
					</View>
				);

			case 'phone':
				return (
					<View>
						<View style={[Styles.care_row, {paddingTop: 4}]}>
							<View style={Styles.icon_small_container}>
								<Icon name={'phone'} style={Styles.icon_small} size={16} />
							</View>
							<Text> Phone: <HyperLink url={this.props.target} title={this.props.value}/> {this.props.extra}</Text>
						</View>
					</View>
				);

			case 'oncall':
				return (
					<View>
						<View style={[Styles.care_row, {paddingTop: 4}]}>
							<View style={Styles.icon_small_container}>
								<Icon name={'phone'} style={Styles.icon_small} size={16} />
							</View>
							<Text> Off Hours: <HyperLink url={this.props.target} title={this.props.value}/> {this.props.extra}</Text>
						</View>
					</View>
				);

			case 'web':
				return (
					<View>
						<View style={[Styles.care_row, {paddingTop: 4}]}>
							<View style={Styles.icon_small_container}>
								<Icon name={'globe'} style={Styles.icon_small} size={16} />
							</View>
							<Text> Website: <HyperLink url={this.props.target} title={this.props.value}/> {this.props.extra}</Text>
						</View>
					</View>
				);

			case 'link':
				return (
					<View>
						<View style={[Styles.care_row, {paddingTop: 4}]}>
							<Text> <HyperLink url={this.props.target} title={this.props.value}/> {this.props.extra}</Text>
						</View>
					</View>
				);

			case 'map':
				return (
					<View>
						<View style={[Styles.care_row, {paddingTop: 4}]}>
							<View style={Styles.icon_small_container}>
								<Icon name={'map-marker-alt'} style={Styles.icon_small} size={16} />
							</View>
							<Text> <HyperLink url={this.props.target} title={this.props.value}/> {this.props.extra}</Text>
						</View>
					</View>
				);

			case 'blank':
				return (
					<View>
						<View style={[Styles.care_row, {paddingTop: 4}]}>
							<View style={Styles.icon_small_container}>
								<Icon style={Styles.icon_small} size={16} />
							</View>
							<Text> {this.props.value}</Text>
						</View>
					</View>
				);

			default:
				return null;
		}
	}

}
