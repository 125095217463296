import React, { Component } from 'react';
import { Platform, Text, View, TouchableOpacity, Dimensions, ScrollView, StyleSheet} from 'react-native';
import ProcedureData from '../components/ProcedureData';
import Styles from '../theme/Styles';

export default class PaiMarquee extends Component {

	containerRef: React.RefObject<ScrollView>;

	constructor(props) {
		super(props);
		this.audioPositionChanged = this.audioPositionChanged.bind(this);
		this.containerRef = React.createRef();
	}

	_onlayout(event, wrd_id, time_start, time_end) {
		var ps = parseInt(event.nativeEvent.layout.x);
		this.positions[wrd_id] = [time_start, time_end, ps];
	}
	
	layout = () => {
		this.setState({changed: true});
		var {width} = Dimensions.get('window');
		this.width = width;
		this.words = this.prepareMarquee();
	}
	
	componentDidMount() { 
		this.audio = ProcedureData.getAudio();
		this._onlayout = this._onlayout.bind(this);
		this.positions = [];
		var {width} = Dimensions.get('window');
		this.width = width;
		this.words = this.prepareMarquee();
	}
	
	componentDidUpdate() {
		this.props.onload(this);
		this.containerRef.scrollTo({x: 0, y: 0, animated: false});
	}
	
	audioPositionChanged(position) {
		position = parseInt(position);
		let c = this.positions.length;
		for(let i = 0; i < c; i++) {
			if (position >= this.positions[i][0] && position < this.positions[i][1]) {
				let ps = this.positions[i][2];
				this.containerRef.scrollTo({x: ps, y: 0, animated: false});
				return;
			}
		}
	}
	
	prepareMarquee() {
		
	  let words = this.audio[0].cc.split("\n");
	  let word_stuff = [];
	  var playerwidth = this.width > 800 ? 760 : this.width - 40;
	  let fs = parseInt(playerwidth / 18.1);
	  
//	  let fs = this.width < 440 ? 18 : 42;
	  for(let i = 0; i < words.length; i++) {
		  let match = words[i].match(/(\d+)\-(\d+)\=(.*)/);
		  if (match) {
			  let time_start = parseInt(match[1]);
			  let time_end = parseInt(match[2]);
			  let txt = match[3] + ' ';

			  let node = (
				<Text key={i} onLayout={ (event) => {this._onlayout(event, i, time_start, time_end)} } style={{fontSize: fs}}>{txt}</Text>
			  );
			  word_stuff.push(node);
			  this.last_end_time = time_end;
		  }
	  }
	  return word_stuff;
  }

  render() {
		const {block} = this.props;
		var {width} = Dimensions.get('window');
		this.width = width;
		var playerwidth = width > 800 ? 760 : width - 40;

		return (
			<View style={[Styles.playerContainer,{width: playerwidth - 10, position: 'absolute', padding: 5, bottom: 50, backgroundColor: '#ffffffd0'}]} onLayout={this.layout}>
				<ScrollView
					  ref={(ref) => this.containerRef = ref}
					  showsHorizontalScrollIndicator={false}
					  horizontal={true}
					  scrollEnabled={false}
					  contentOffset={{x: 0, y: 0}}
				>
					{this.words}
				</ScrollView>
			</View>
		);
	}

}