type AlertType = 'info' | 'warn' | 'error' | 'success'

// https://github.com/testshallpass/react-native-dropdownalert

export type DropdownType = {
  alertWithType: (type: AlertType, title: string, message: string) => void
}

export class DropDownHolder {
  static dropDown: DropdownType;

  static setDropDown(dropDown: DropdownType) {
    this.dropDown = dropDown;
  }

  static alert(type: AlertType, title: string, message: string, interval) {
    this.dropDown.alertWithType(type, title, message, {}, interval);
  }
  
  static close() {
	this.dropDown.close('cancel');	// ACTION.cancel
  }
}
