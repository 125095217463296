import React, { Component } from 'react';
import { Platform, Text, View, TouchableOpacity, TextInput, Button} from 'react-native';
import ProcedureData from '../components/ProcedureData';

import Styles from '../theme/Styles';
import ThemeMedia from '../theme/ThemeMedia';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from '../components/SimpleRadioButton';
import Storage from '../components/Storage';
import uuidv4 from '../components/uuid';
import DropdownAlert from 'react-native-dropdownalert';
import {DropDownHolder, DropdownType} from './DropDownHolder';

// Normally appears on the Success Page

class Feedback extends Component {

	state = {
		value: 0,
		text: '',
	}

	static clear() {
		Storage.local_removeValue('feedbackComment');
		Storage.local_removeValue('uuid');
		Storage.local_removeValue('satisfied');
		Storage.local_removeValue('helpful');
	}
	
	constructor(props) {
		super(props);
		this.radio_props = [
		  {label: 'No', value: 0, image: 'no' },
		  {label: 'Somewhat', value: 1, image: 'soso' },
		  {label: 'Yes', value: 2, image: 'yes' },
		];
		for(var i = 0; i < this.radio_props.length; i++) {
			let rec = this.radio_props[i];
			rec.resource0 = ThemeMedia.getResource(rec.image + '0').object;
			rec.resource1 = ThemeMedia.getResource(rec.image + '1').object;
		}
		this.state.text = Storage.local_getValue('feedbackComment', '');
		this.uuid = Storage.local_getValue('uuid', '');
		if (this.uuid == '') {
			this.uuid = uuidv4();
			Storage.local_setValue('uuid', this.uuid);
		}
	}

	onPressButton(keyname, value) {
		Storage.local_setValue(keyname, (value).toString());
		this.setState({value : value});		// state is not used here
	}

	drawFaces(keyname) {
		var txt = Storage.local_getValue(keyname, '-1');
		var v = parseInt(txt);
		return (
			<RadioForm
				style={Styles.f_table}
				radioStyle={Styles.f_cell}
				labelStyle={Styles.f_text}
				imageStyle={Styles.f_image}
			  radio_props={this.radio_props}
			  initial={v}
			  onPress={(value) => {
					this.onPressButton(keyname, value);
			  }}
			/>
		);
	};

	_sendData(payload) {
		var api_url = Storage.source(0);
		fetch(api_url + '/feedback', {
			method: 'POST',
			headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			body: JSON.stringify(payload),
		}).then((responseJson) => {
			DropDownHolder.alert('success', 'Thank You', 'Your feedback was sent successfully', 4000);
			return 1;
		}).catch((error) => {
			DropDownHolder.alert('error', 'Error', 'Your feedback was not submitted. The error was: ' + error, 4000);
		});
	}
	
	_getValue(keyname) {
		var txt = Storage.local_getValue(keyname, '-1');
		var v = parseInt(txt);
		switch(v) {
			case -1:	return '';
			case 0:		return 'no';
			case 1:		return 'somewhat';
			case 2:		return 'yes';
			default:	return  txt;
		}
	}

/*
	to do: include selected client, + git information
			let hash = body.match(/\tcommit: (.*?)\n/);
			let source = body.match(/\tsource: (.*?)\n/);
*/
	onPressSend = () => {
		let content_text = ProcedureData.contentText();
		let satisfied = this._getValue('satisfied');
		let helpful = this._getValue('helpful');
		if (this.state.text === '' &&
			satisfied === '' && 
			helpful === '') {
				DropDownHolder.alert('error', 'Error', 'Please select a rating, or enter a comment', 4000);
				return;
		}
		var payload = {
			uuid: 		this.uuid,
			source:		content_text.splash.appname,
			os:			Platform.OS,
			satisfied:	satisfied,
			helpful:	helpful,
			feedback:	this.state.text,
		}
		this._sendData(payload);
	}
	
	onChangeText = (text) => {
		this.setState({text});
		Storage.local_setValue('feedbackComment', text);
	}
	
	render() {
		let wd = this.props.width;
		let style = {
			width: wd - 10,
			borderColor: '#999',
			borderWidth: 3,
//			shadowOffset:{  width: 1,  height: 1,  },
//			shadowColor: '#000',
//			shadowOpacity: 0.5,
//			shadowRadius: 10,
			padding: 20,
			marginBottom: 30,
		};
//				<View style={{shadowOpacity: 0, shadowRadius: 1, shadowColor: '#fff',}}>
		return (
			<View style={style} >
				<View>
					<Text style={Styles.f_head}>Were you satisified with the procedure?</Text>
					{this.drawFaces('satisfied')}
				</View>
				<View>
					<Text style={Styles.f_head}>Was this guide helpful?</Text>
					{this.drawFaces('helpful')}
				</View>
				<View>
					<Text style={Styles.f_head}>Tell us about your experience</Text>
				</View>
				<View>
					<Text style={Styles.f_text}>Please share what you liked about this app, and what you did not, so we can continue to improve. Since this is completely anonymous, you will not receive a reply.</Text>
				</View>
				<View>
					<TextInput
						style={Styles.f_input}
						multiline = {true}
						numberOfLines = {4}
						onChangeText={this.onChangeText}
						value={this.state.text}
					/>
				</View>
				<View style={Styles.f_right}>
					<Button
					  style={Styles.f_button}
					  onPress={this.onPressSend}
					  title="Send Feedback"
					  color={Styles.f_buttoncolor.color}
					  accessibilityLabel="Send Feedback to PatientApps"
					/>
				</View>
			</View>
		);
	}
}

export default Feedback;