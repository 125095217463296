import React from "react";
import {
  //  Platform,
  Dimensions,
  ScrollView,
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  Animated
} from 'react-native';

import PaiPage from '../components/PaiPage';
import ProcedureData from '../components/ProcedureData';
import Storage from '../components/Storage';
import NavigationService from '../components/NavigationService';
import { withNavigation, DrawerActions } from 'react-navigation';
import Icon from 'react-native-vector-icons/FontAwesome5';
import {HeaderBar} from '../theme/Styles';
import GestureRecognizer, {swipeDirections} from '../components/GestureRecognizer';
import { Platform } from 'react-native';
import {debounce} from 'lodash';

var isInitialLoad;
var loadCount;

class ContentPage extends React.Component {
  constructor(props) {
    super(props);
    var { width, height } = Dimensions.get('window');
    this.state = {
      page: {},
      lastpagenum: -1,
      background: false,
      width: width,
      height: height,
      windowWidth: width,
      windowHeight: height,
	  windowWidthLast: false,
      lastWidth: -1,
	  imageTicker: false,
	  imageTickerLast: false,
	  subimage: false,
    };

    this._loadData = this.loadData.bind(this);
    this.setBackground = this.setBackground.bind(this);
	this._onPageLoad = this._onPageLoad.bind(this);
	this.updateDimensions = debounce(this.onChange.bind(this), 100);

	// This is for the parallex effect on the Background image
    this.scrollY = new Animated.Value(0);
    this._onAnimatedScroll = Animated.event(
      [{ nativeEvent: { contentOffset: { y: this.scrollY } } }],
      {
        listener: this._onScroll,
        useNativeDriver: false /*props.useNativeDriver*/
      }
    );
	this.images = {};		// background images
    isInitialLoad = true;
    loadCount = 0;
  }

  static navigationOptions = ({ navigation }) => {
    var currentPage = NavigationService.getCurrentPage();
    var pageNum = navigation.getParam('page', 1);
	pageNum = NavigationService.setCurrentPage(pageNum);
	let titleobject = ProcedureData.getPageTitle(pageNum);
	return HeaderBar(navigation, titleobject);
  }; 

  // called if change in state or props
  static getDerivedStateFromProps(props, state) {
    let newpage = typeof props.page === 'object' ? props.page.pagenum : -1;
    let result = {};
    let changed = false;
    if (newpage != state.lastpagenum && newpage != -1) {
      result.page = props.page;
      result.lastpagenum = props.page.pagenum;
      changed = true;
    }

    if (state.width != state.lastWidth || state.windowWidth != state.windowWidthLast) {
      result.width = state.width;
      result.height = state.height;
      result.lastWidth = state.width;
      result.windowWidth = state.windowWidth;
	  result.windowWidthLast = state.windowWidth;
      result.windowHeight = state.windowHeight;
      changed = true;
    }
	
	// an image has been loaded from the database, and is ready for display
	if (state.imageTicker != state.imageTickerLast) {
		result.imageTicker = state.imageTicker;
		result.imageTickerLast = state.imageTicker;
		result.subimage = state.subimage;
      changed = true;
	}
	
    return changed ? result : null;
  }

	onChange(object) {
		var {width, height} = Dimensions.get('window');
		this.setState({changes: 1, windowWidth: width, windowHeight: height});
	}

  _onScroll = e => {
    const contentOffsetY = e.nativeEvent.contentOffset.y;
  };

  loadData() {
    var pagenum = NavigationService.getCurrentPage();
    this.setState({ page: ProcedureData.getPageData(pagenum)});	// , visibleDropDown: 9999 
    return pagenum;
  }

  onDismiss = () => {
    this.setState({ isSplash: false });
  };

  setBackground(filename) {
    if (filename !== this.state.background) {
      this.setState({ background: filename });
    }
  }
  
  _onPageLoad(params) {
	let {action, state, result} = params;
	if (action.type == 'Navigation/NAVIGATE' && action.routeName == 'content') {
		if (typeof action.params.page !== 'undefined') {
			let pg = parseInt(action.params.page);
			NavigationService.setCurrentPage(pg);
			this.loadData();
			this.scrollToPosition(-1)
		}
	}
  }

  // fetch data here
  componentDidMount() {
    this._ismounted = true;
	Dimensions.addEventListener('change', this.updateDimensions);
	Storage.on('pageBlur', this._onPageLoad);
    this.loadData();
  }

  componentWillUnmount() {
	Storage.off('pageBlur', this._onPageLoad);
	Dimensions.removeEventListener('change', this.updateDimensions);
    this._ismounted = false;
  }

  // navigation when using (<) and (>)
  // executed after Render
  componentDidUpdate(prevProps, prevState) {
    var pp = prevProps.navigation.getParam('page', -1);
    var cp = this.props.navigation.getParam('page', -1);
    var j = NavigationService.getCurrentPage();
    if (pp !== cp && cp !== -1) {
      this.loadData();
    } else if (j === -1 && cp !== -1) {
      this.loadData();
    }
	ProcedureData.loadQueuedImages(this);
  }

/*  // executed before render
  shouldComponentUpdate(props, state) {
    var pp = props.navigation.getParam('page', -1);
    var cp = this.props.navigation.getParam('page', -1);
    var new_wd = state.windowWidth;
    var old_wd = this.state.windowWidth;
    return (
      (cp !== -1 && pp !== -1) || !this.state.page.length || new_wd !== old_wd
    );
  } */

	_renderBackground(width, height, resource) {
		if (!resource) {
			return null;
		}

		const topOrigin = true;		// was false
		const backgroundScale = 3;
		const parallaxBackgroundScrollSpeed = 6;

		const translateY = !height
		  ? 0
		  : this.scrollY.interpolate({
			inputRange: [...(topOrigin ? [-height, 0] : [0]), height],
			outputRange: [
			  ...(topOrigin ? [-(height / backgroundScale) + height, 0] : [0]),
			  -(height / parallaxBackgroundScrollSpeed)
			],
			extrapolateLeft: 'extend',
			extrapolateRight: 'extend'
		  });

		let imagedata = ProcedureData.getQueuedImage(this, resource);

		const style = {
			position: 'absolute',
			width,
			height,
			transform: [{translateY}]
		};

		// why key=??  -- resolves missing images in iOS -- https://github.com/facebook/react-native/issues/9195
		return (
			<Animated.View style={style}>
				<Image
					key={this.state.imageTicker}
					style={{
						width: width,
						height: height,
						position: 'absolute',
						top: 0,
						left: 0,
					}}
					source={imagedata}
				/>
			</Animated.View>
		);
	}

  onLayout = e => {
    var { width, height } = Dimensions.get('window');
    this.setState({
      width: e.nativeEvent.layout.width,
      height: e.nativeEvent.layout.height,
      windowWidth: width,
      windowHeight: height
    });
  };

  	onSwipeHorizontal(state, direction) {
		var pagenum = NavigationService.getCurrentPage();
		let nextpage = ProcedureData.getNextPage(direction, pagenum);
        this.props.navigation.navigate('content', { page: nextpage });
	}

	setScrollViewRef = (element) => {
		this.scrollViewRef = element;
	};
	
	scrollToPosition = (y) => {
		if (y == -1) {
			// animation must be false, otherwise large whitespace areas on iOS
			this.scrollViewRef.scrollTo({x: 0, y: 0, animated: false});
		} else {
			if (y < 0) y = 0;
			this.scrollViewRef.scrollTo({x: 0, y: y, animated: true});
		}
	}
	
  render() {
    let resource, imageheight;
    let width = this.state.windowWidth;
    if (typeof this.state.background === 'string') {
		let alternativename = this.state.background;
		alternativename += width < 440 ? '.0' : '.1';
		resource = ProcedureData.getImageInfo(alternativename);
		if (resource === null) {
			alternativename = this.state.background;
			resource = ProcedureData.getImageInfo(alternativename);
		}
		imageheight = (width * resource.ht) / resource.wd;
    } else {
      resource = false;
      imageheight = 1;
    }

	const config = {
      velocityThreshold: 0.3,
      directionalOffsetThreshold: 80
    };
	
    return (
	  <GestureRecognizer 
			onSwipeLeft={(state) => this.onSwipeHorizontal(state, 1)}
			onSwipeRight={(state) => this.onSwipeHorizontal(state, -1)}
			config={config} 
			onLayout={this.onLayout}
			style={[
			  styles.container,
			  { backgroundColor: '#fff', opacity: 1, flex: 1 }
			]}
      >
        {this._renderBackground(width, imageheight, resource)}
        <ScrollView
			style={[styles.container, { flex: 1 }]}
			contentContainerStyle={styles.contentContainer}
			onScroll={this._onAnimatedScroll}
			scrollEventThrottle={16}
			ref={this.setScrollViewRef}
        >
          <PaiPage
            {...this.props}
            {...this.state}
            onBackground={this.setBackground}
            width={width}
			onScrollToPosition={this.scrollToPosition}
          />
        </ScrollView>
	  </GestureRecognizer>
    );
  }
}

export default withNavigation(ContentPage);

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  contentContainer: {
    paddingTop: 30
  }
});
