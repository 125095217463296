import React, {Component} from 'react';
import {NavigationActions} from 'react-navigation';
import PropTypes from 'prop-types';
import {TouchableOpacity, ScrollView, Text, View, StyleSheet} from 'react-native';
import { DrawerActions } from 'react-navigation-drawer';
import Styles, {Sharing, PerformSharing} from '../theme/Styles';
import Storage from '../components/Storage';
import ProcedureData from '../components/ProcedureData';
import NavigationService from '../components/NavigationService';
import Resource from '../components/Resource';

// www.flaticon.com  and  icomoon.io
import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require("../theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);


// to do: hilight active page

class DrawerScreen extends Component {
  
	constructor (props) {
		super(props);
		this.state = {page: -1};
		this.navlist = [];
		let content_text = ProcedureData.contentText();
        let firstHeader = true;
        let i, node, page;
		for(i = 0; i < content_text.content.length; i++) {
			page = content_text.content[i];
			if (page.type === 'header' || page.type === 'subheader') {
				node = {
					type: page.type, 
					caption: page.caption, 
					page: i + 1,
				};
				if (page.type === 'subheader' && typeof page.icon !== 'undefined') {
					node.icon = page.icon;
				}
				this.navlist.push(node);
			}
            if (page.type === 'header' && firstHeader) {
                firstHeader = false;
                if (content_text.macro.title_page) {
                    this.navlist.push({
                        type: 'subheader', 
                        caption: content_text.macro.title_page,
                        page: -100,
                        icon: 'open-book',
                    });
                }
            }
		}
        
        if (content_text.macro.other) {
            let menu = {
                caption: content_text.macro.other,
                submenu: [
                ],
            };
            
            if (content_text.macro.share) {
                menu.submenu.push({caption: content_text.macro.share + '...', icon: 'share2', page: -190});
            }
            
            if (menu.submenu.length) {
                this.navlist.push({
                    type: 'header', 
                    caption: menu.caption, 
                    page: 0,
                });
                for(let i = 0; i < menu.submenu.length; i++) {
                    let item = menu.submenu[i];
                    this.navlist.push({
                        type:    'subheader', 
                        caption: item.caption,
                        page:    item.page,
                        icon:    item.icon,
                    });
                }
            }
        }

		this.theme = StyleSheet.flatten(Styles.theme);
		NavigationService.setDrawer(this);
	}

    sharing(content_text) {
        let menu = {
            caption: content_text.macro.other,
            submenu: [
                {caption: content_text.macro.share + '...', icon: 'share2', page: -190},
            ]
        };
        
        return menu;
    }
    
	componentDidMount() { 
		this.setState({page: NavigationService.getCurrentPage()});
	}
	
	navigateToScreen = (page) => () => {
        if (page > 0) {
            this.props.navigation.navigate('content', {page: page});
        } else {
            switch(page) {
                case -100:      // title page
                    Storage.trigger('nav_title', {});
                    break;
                case -101:      // team
                    Storage.trigger('nav_team', {});
                    break;
                case -102:      // settings
                    Storage.trigger('nav_settings', {});
                    break;
                case  -190:
                    let content_text = ProcedureData.contentText();
                    let procedurecode = Storage.local_getValue('procedure', null);
                    //let procedurecode = Resource.detectedProduct();
                    // perform sharing
                    let res = PerformSharing(content_text, procedurecode);
                    // Allow Share icon to be intecepted
                    if (res !== null) {
                        Resource.javascriptEvent('share', res);
                    }
                    break;
                
            }
        }
		setTimeout(() => this.props.navigation.dispatch(DrawerActions.closeDrawer()), 0);
	}
	
	drawIcon(node) {
		if (typeof node.icon !== 'undefined') {
			return (
				<IconMoon name={node.icon} size={18} />

			);
		} // marginRight: 10, 
		return (
			<IconMoon name={'file-empty'} size={18} />
		);
	}

	generateMenu() {
		return this.navlist.map((value, key) => {
			if (value.type === 'header') {
				return (
					<TouchableOpacity key={key} style={Styles.menuItemHeader}>
						<Text style={Styles.menuTextHeader} onPress={this.navigateToScreen(value.page)}>
							{value.caption}
						</Text>
					</TouchableOpacity>
				);
			}
			let style = {};
			let textstyle = {};
			if (value.page == this.state.page) {
				style.backgroundColor = this.theme.color;
				textstyle.color = '#fff';
			}
			return (
				<TouchableOpacity key={key} style={[Styles.menuItem, style]} onPress={this.navigateToScreen(value.page)}>
					{this.drawIcon(value)} 
					<Text style={[Styles.menuText, textstyle]} >
						{value.caption}
					</Text>
				</TouchableOpacity>
			);
		});
	}

	render () {
		return (
			<ScrollView style={Styles.menuPanel} contentContainerStyle={{paddingBottom: 20}}>{this.generateMenu()}</ScrollView>
		);
	}
}

/*DrawerScreen.propTypes = {
  navigation: PropTypes.object
}; */

export default DrawerScreen;
